import { fetcher } from "@/utils/api"
import { TContractBody } from "../types"

// export const getContracts = () => {
//   return fetcher("/contracts")
// }

// export const getContractsByTemplate = (template) => {
//   return fetcher(`/contracts/templates/${template}`)
// }

// export const signContracts = (uid) => {
//   return fetcher(`/contracts/${uid}/sign`)
// }

// export const createContract = ({
//   project,
//   parties = [],
//   template = "",
//   customer,
//   content,
//   variables,
// }) => {
//   return fetcher("/contracts", {
//     method: "POST",
//     body: JSON.stringify({
//       project,
//       parties,
//       content,
//       template,
//       customer,
//       variables,
//     }),
//   })
// }

export const getContracts = async (): Promise<any> => {
  try {
    const response = await fetcher("/contracts")
    return response
  } catch (error) {
    console.error("Failed to fetch contracts:", error)
    return undefined
  }
}

export const getContractsByTemplate = async (
  template: string,
): Promise<any> => {
  try {
    const response = await fetcher(`/contracts/templates/${template}`)
    return response
  } catch (error) {
    console.error("Failed to fetch contracts by template:", error)
    return undefined
  }
}

export const signContracts = async (uid: string): Promise<any> => {
  try {
    const response = await fetcher(`/contracts/${uid}/sign`)
    return response as { success: boolean; has_all_signed: boolean }
  } catch (error) {
    console.error("Failed to sign contracts:", error)
    return undefined
  }
}

export const updateContractApprovalState = (
  uid: string,
  isApproved: boolean,
) => {
  try {
    return fetcher(`/contracts/${uid}/approve`, {
      method: "PUT",
      body: JSON.stringify({ isApproved }),
    })
  } catch (error) {
    console.error("Failed to update contract approval state:", error)
    return undefined
  }
}

export const createContract = async (body: TContractBody): Promise<any> => {
  try {
    const response = await fetcher("/contracts", {
      method: "POST",
      body: JSON.stringify(body),
    })
    return response
  } catch (error) {
    console.error("Failed to create contract:", error)
    return undefined
  }
}

export const sendContract = async ({
  contractId,
  spaceId,
  receiverIds,
}: {
  contractId: string
  spaceId: string
  receiverIds: string[]
}) => {
  try {
    await fetcher(`/contracts/${contractId}/send?space=${spaceId}`, {
      method: "POST",
      body: JSON.stringify({ receiverIds }),
    })
  } catch (error) {
    console.error("Failed to send contract:", error)
    return undefined
  }
}
